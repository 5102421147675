// API
import api from '@/mixins/api';
import gv from '@/mixins/globalValiables';

const token = {
  namespaced: true,
  state:{
    list: [],
    count: 0,
  },

  getters:{
    isloaded : state =>{
      if (! state.list.length) {
        return false;
      } else {
        return true;
      }
    },
    list : state =>{
      return state.list;
    },
    count : state =>{
      return state.count;
    },
  },

  mutations:{

  },

  actions:{
    async load({ commit, state }){
      this.dispatch('token/reset');
      await this.dispatch('token/get');
    },
    // WebAPIをコールして読み込み（非同期処理）
    async get({ commit, state }){
      const result = await api.methods.getTokens();
      state.list = result.tokens;
      state.count = state.list.length;
    },

    async save({ commit, state }, post={}){
      const result = await api.methods.saveToken(post);
      state.list.push(result.token);
      state.count = state.list.length;
    },

    reset({ commit, state }){
      state.list = [];
      state.count = 0;
    }
  }
}

export default token;
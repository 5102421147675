import { ref } from 'vue'
import store from '../store'
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import MetaMaskOnboarding from '@metamask/onboarding';
import StandardERC777 from '../abi/StandardERC777.json';

const inProgress = ref(false)
const inProgressMessage = ref(null) 
const inProgressDescription = ref(null)

const alert = ref(false)
const alertType = ref(null)
const alertMessage = ref(null)
const alertDescription = ref(null)
const isWeb3Enabled = ref(false)
const currentChain = ref(null)
const isDeployComplete = ref(false)
const transactionHash = ref(null)
const newContractAddress = ref(null)


/*************************************************************
 * プライベート変数
 */
let web3Provider = null
let web3 = null

/*************************************************************
 * イベントハンドラ
 */
// ネットワークが変更された時にchainIdHexを同期する
if(window.ethereum) {
    window.ethereum.on('chainChanged', (chain) => {
        currentChain.value = chain
    })
}

/*************************************************************
 * プライベート関数
 */
// web3プロバイダの初期化
const web3Initialize = async() => {
    inProgress.value = true
    inProgressMessage.value = '初期化中…'
    inProgressDescription.value = 'Web3プロバイダを検出しています。'
    const provider = await detectEthereumProvider();
    if (provider) {
        // 検出したプロバイダとwindow.ethereumが同じである事を確認
        if (provider == window.ethereum) {
            // 同じ場合は正常
            isWeb3Enabled.value = true
            // 接続されているネットワークを判別する為のChainID(HEX)を取得
            currentChain.value  = await window.ethereum.request({ method: 'eth_chainId' });
            web3Provider = window.ethereum
            web3 = new Web3(web3Provider)
        } else {
            // 異なる場合はエラー
            alert.value = true
            alertType.value = 'error'
            alertMessage.value = 'Error'
            alertDescription.value = 'Web3プロバイダが複数検出されました。MetaMaskのみインストールされた状態で再度お試しください。'
        }
    } else {
        alert.value = true
        alertType.value = 'error'
        alertMessage.value = 'Error'
        alertDescription.value = 'Web3プロバイダの検出に失敗しました。MetaMaskのインストール後にもう一度お試しください。モバイルユーザーでこのメッセージが表示される場合、MetaMaskのアプリ内ブラウザより本Webページを開いて再度お試しください。'
    }
    inProgress.value = false
}

/*************************************************************
 * Vueコンポーネントにエクスポートする関数
 */
export default function useWeb3() {

    const onboarding = async () => {
        const onboarding = new MetaMaskOnboarding()
        onboarding.startOnboarding()
    }

    // コントラクトのデプロイ
    const deployContract = async (values) => {
        // MetaMaskで選択中のネットワークとデプロイ先のネットワークが一致しているかを確認
        if(currentChain.value !== values.targetchain){
            alert.value = true
            alertType.value = 'error'
            alertMessage.value = 'Error'
            alertDescription.value = 'MetaMaskで選択中のネットワークとデプロイ先のネットワークが一致しません。'
            return;
        }

        inProgress.value = true
        inProgressMessage.value = 'コントラクトのデプロイ'
        inProgressDescription.value = 'MetaMask連携の承認を待っています…'
        // ウォレットを接続
        try {
            await web3Provider.request({ method: 'eth_requestAccounts' })
        } catch (error) {
            if (error.code === 4001) {
                alert.value = true
                alertType.value = 'error'
                alertMessage.value = 'Error'
                alertDescription.value = 'MetaMaskとの連携が許可されませんでした。'
            } else {
                alert.value = true
                alertType.value = 'error'
                alertMessage.value = 'Error'
                alertDescription.value = error
            }
            inProgress.value = false
            return;
        }

        inProgressDescription.value = 'デプロイに必要なデータの準備中…'

        const fromAddress = await web3.eth.getCoinbase() // トランザクションを発行した人のアドレス
        const tokenContract = new web3.eth.Contract(StandardERC777.abi)
        const deployOptions = {
            data: StandardERC777.bytecode,
            // デプロイするコントラクトのコンストラクターに渡す引数
            arguments: [
                values.tokenname,
                values.symbol,
                [fromAddress],
                web3.utils.toWei(values.totalsupply.toString(), 'GWei'),
                fromAddress,
            ],
        };

        // GAS代を計算
        const gas = await tokenContract.deploy(deployOptions).estimateGas()
        const gasPrice = await web3.eth.getGasPrice(); // 直近のガス価格の中央値
        const sendOptions = {
            from: fromAddress,
            gas: gas,
            gasPrice: gasPrice * 2,
        };

        inProgressDescription.value = 'デプロイ中…'

        tokenContract.deploy(deployOptions)
            .send(sendOptions)
            .on('error', function(error){
                if (error.code === 4001) {
                    alert.value = true
                    alertType.value = 'error'
                    alertMessage.value = 'Error'
                    alertDescription.value = 'トランザクションの発行が拒否されました。'
                } else {
                    alert.value = true
                    alertType.value = 'error'
                    alertMessage.value = 'Error'
                    alertDescription.value = error.message
                }
                inProgress.value = false
            })
            .on('transactionHash',function(txhash){
                transactionHash.value = txhash
                inProgressDescription.value = `トランザクションを発行「${txhash}」`
            })
            .on('receipt', function(receipt){
                inProgressDescription.value = `コントラクトアドレス「${receipt.contractAddress}」`
            })
            .on('confirmation', function(confirmationNumber, receipt){
                console.log(`confirmation: ${confirmationNumber}`)
                console.log(receipt)
            })
            .then(function(newContractInstance){
                console.log('Deploy success')
                console.log(newContractInstance.options.address)
                newContractAddress.value = newContractInstance.options.address
                isDeployComplete.value = true
                inProgress.value = false
                alert.value = true
                alertType.value = 'success'
                alertMessage.value = 'トークンの作成に成功しました'
                alertDescription.value = `コントラクトアドレス「${newContractAddress.value}」トークンシンボル「${values.symbol}」少数桁数「18」`
                // DBに保存
                store.dispatch('token/save', values);
            });
    }

    return {
        alert,
        alertType,
        alertMessage,
        alertDescription,
        inProgress,
        inProgressMessage,
        inProgressDescription,
        isWeb3Enabled,
        web3Provider,
        onboarding,
        currentChain,
        deployContract,
        isDeployComplete,
        transactionHash,
        newContractAddress,
    }
}

web3Initialize()
<template>
    <a-layout>
        <a-layout-header>
            <div class="logo"><img src="@/assets/img/logo-asreal-white.svg" alt="AsReal"></div>
            <div class="links">
                <a @click="showContact">CONTACT US</a>
            </div>
        </a-layout-header>

        <a-layout-content>

            <section class="section section-mv">
                <div class="txt"><p>無料で独自仮想通貨(トークン)を発行</p></div>
                <div class="logo"><img src="@/assets/img/logo.svg" alt="Crypto generator"></div>
                <div class="token-result">
                    <p class="num">{{tokenNum}}</p>
                    <p class="attr">Generated token</p>
                </div>
                <div class="btn-wrap">
                    <a @click="startScroll('token')" class="btn btn-primary"><span>独自通貨を発行する →</span></a>
                </div>
                <div class="link-wrap">
                    <a @click="startScroll('about')" class="link-img"><img src="@/assets/img/link-more.svg" alt="More"></a>
                </div>
            </section>
            <section ref="about" class="section mb-xxl"><div class="container">
                <h2 class="title-sub mb-md">このサイトはブロックチェーンの技術的理解を深めることを目的としたサイトです。</h2>
                <div class="mb-md"><p>本サービスによって作成したトークン(独自仮想通貨)を詐欺・勧誘・ギャンブル等その他、<br>一切の不正行為に使用しない事をお約束頂ける方のみご利用頂けます。<br>
                また商用利用は禁止しています。商用利用をご検討の方は以下よりお問い合せください。</p></div>
                <div><img src="@/assets/img/logo.svg" width="330"></div>
            </div></section>
            <section class="section mb-md"><div class="container">
                <h2 class="title-sub mb-md">PolygonまたはEthereumブロックチェーン上に仮想通貨を作成できます。</h2>
                <div class="token-result">
                    <p>発行された仮想通貨</p>
                    <p class="num">{{tokenNum}}</p>
                    <p class="attr">Generated token</p>
                </div>
            </div></section>


            <InProgress />

            <section ref="token" class="section section-token"><div class="container">
                <div class="txt mb-md">ご利用には仮想通貨ウォレットアプリ<a class="link-txt" target="_blank" href="https://metamask.io/">MetaMask（メタマスク）</a>のインストールが必要です。<br>
                仮想通貨の発行にはガス代がかかります。</div>
                <GenerateToken />
                <Alert />
                <div class="form-note"><ul class="list-notes">
                    <li>トークンのデプロイには早くても30秒程度時間を要します、<br>進捗状況はMetaMaskの「アクティビティ」よりご確認ください。</li>
                    <li>「発行量」は作成するトークンの総量です(単位はGWei)</li>
                    <li>1GWei=0.000000001ETH</li>
                    <li>本サービスからトークンを発行することで、運営者が何らかの利益を得るような事は一切ありません。</li>
                    <li>トークン作成時に発生するGAS代は選択したネットワークに対して支払われます。</li>
                </ul></div>
            </div></section>
        </a-layout-content>

        <a-layout-footer>
            <div class="logo"><img src="@/assets/img/logo-asreal.svg" alt="AsReal"></div>
            <div class="btn-wrap">
                <a @click="showContact" class="btn btn-default">
                    <span class="txt-ja">メタバース・ブロックチェーン・NTFに関するお問い合わせはこちら</span>
                    <span class="txt-en">CONTACT US</span>
                </a>
            </div>
            <div class="copy">
                &copy; 2022 ASREAL inc.
            </div>
        </a-layout-footer>
        <a-modal v-model:visible="contactVisible" wrapClassName="modal-contact" width="800px" title="Contact Us">
            <ContactModal />
        </a-modal>
    </a-layout>
</template>

<script>
// import Chain from "./components/Chain.vue";
import { ref } from "vue";
import GenerateToken from "./components/GenerateToken.vue";
import InProgress from "./components/InProgress.vue";
import Alert from "./components/Alert.vue";
import ContactModal from "./components/ContactModal.vue";

export default {
    name: "App",
    components: {
        // Chain,
        GenerateToken,
        InProgress,
        Alert,
        ContactModal
    },
    setup() {
        const contactVisible = ref(false);

        const showContact = () => {
            contactVisible.value = true;
        };
        return {
            contactVisible,
            showContact
        }
    },
    computed: {
        tokenNum() {
            return this.$store.getters['token/count'];
        },
    },
    async created() {
        await this.$store.dispatch('token/load');
    },
    methods: {
        startScroll(target) {
            window.scrollTo({
                top: this.$refs[target].offsetTop,
                behavior: "smooth"
            });
        }
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
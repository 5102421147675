import Vuex from 'vuex'
import token from './token'

const store = new Vuex.Store({

  modules:{
    token : token,
  },

  state: {
  },
  mutations: {
  },
  actions: {
  },
})

export default store
import gv from '@/mixins/globalValiables';
import axios from 'axios';

export default {
  methods: {
    async getTokens() {
      let res;
      let url = process.env.VUE_APP_API_ENDPOINT + 'tokens';
      await new Promise(function (resolve) {
        axios.get(url)
          .then(respons => {
            res = respons.data.data
            resolve(res);
          })
          .catch(({response}) => {
            gv.methods.debug(response);
          });
      })
      return res;
    },
    async saveToken(post = {}) {
      let res;
      let url = process.env.VUE_APP_API_ENDPOINT + 'tokens/save';
      await new Promise(function (resolve) {
        axios.post(url, post)
          .then(respons => {
            res = respons.data.data
            resolve(res);
          })
          .catch(({response}) => {
            gv.methods.debug(response);
          });
      })
      return res;
    },
    async postContact(post={}) {
      let res;
      let url = process.env.VUE_APP_API_ENDPOINT + 'contact';
      await new Promise(function (resolve) {
        axios.post(url, post)
          .then(respons => {
            res = respons.data.data
            resolve(res);
          })
          .catch(({response}) => {
            gv.methods.debug(response);
          });
      })
      return res;
    }
  }
}
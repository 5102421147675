<template>
    <div>
        <div v-if="!inProgress && !isWeb3Enabled" v-bind:style="{ textAlign: 'center', padding: '16px 16px 16px' }">
            <a-button type="primary" @click="onboarding">MetaMaskをインストールする</a-button>
        </div>

        <a-form
            :model="formState"
            name="basic"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 24 }"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
        >

            <div class="form-item-step1">
            <a-form-item
                name="targetchain"
                label="チェーンネットワーク"
                has-feedback
                :rules="[
                    {
                        required: true,
                        message: 'この項目は必須です。' 
                    }
                ]"
            >
                <a-select
                    ref="select"
                    v-model:value="formState.targetchain"
                    placeholder="選択してください"
                    @change="handleChangeTargetNetwork"
                >
                    <a-select-option value="0x1">Ethereum メインネット</a-select-option>
                    <a-select-option value="0x4">Ethereum テストネット Rinkeby</a-select-option>
                    <a-select-option value="0x89">Polygon メインネット</a-select-option>
                    <a-select-option value="0x13881">Polygon テストネット Mumbai</a-select-option>
                </a-select>
            </a-form-item>
            </div>
            <div class="form-item-step2">
            <a-form-item
                label="トークン名"
                name="tokenname"
                has-feedback
                :rules="[
                    {
                        required: true,
                        message: 'トークン名は半角英数字のみ使用可能です。(20文字以内)',
                        pattern: '^[a-zA-Z0-9]+$',
                        max: 20,
                    },
                ]"
            >
                <a-input v-model:value="formState.tokenname" placeholder="例: MyToken" />
            </a-form-item>
            </div>
            <div class="form-item-step3">
            <a-form-item
                label="シンボル"
                name="symbol"
                has-feedback
                :rules="[
                    {
                        required: true,
                        message: 'シンボルは半角英数字のみ使用可能です。(3-5文字)',
                        pattern: '^[a-zA-Z0-9]+$',
                        min: 3,
                        max: 5,
                    },
                ]"
            >
                <a-input v-model:value="formState.symbol" placeholder="例: BTC" />
            </a-form-item>
            </div>
            <div class="form-item-step4">
            <a-form-item
                label="発行量(GWei)"
                name="totalsupply"
                has-feedback
                :rules="[
                    {
                        required: true,
                        message: '1〜100000000000000000の範囲で入力して下さい。',
                        type: 'number',
                        min: 1,
                        max: 100000000000000000,
                    },
                ]"
            >
                <a-input-number
                    v-model:value="formState.totalsupply"
                    style="min-width: 200px"
                />
            </a-form-item>
            </div>
            <div class="form-item-acceptterms">
            <a-form-item
                label=""
                name="acceptterms"
                :rules="[
                    {
                        required: true,
                        message: '利用条件の同意は必須です。',
                    },
                ]"
            >
                <a-checkbox-group v-model:value="formState.acceptterms">
                    <a-checkbox value="1" name="type">私は本サービス<a class="link-txt" @click="showModal">利用条件</a>に同意します</a-checkbox>
                </a-checkbox-group>
            </a-form-item>
            </div>


            <a-form-item :wrapper-col="{ span: 28 }">
                <a-button class="btn btn-primary" html-type="submit" v-bind:disabled=!isWeb3Enabled v-bind:loading=inProgress >独自通貨を発行する →</a-button>
            </a-form-item>
        </a-form>

        <a-modal v-model:visible="visible" wrapClassName="modal-terms" width="800px" title="本サービス利用条件" @ok="handleOk">
            <p>Token Generatorのサービス（以下、本サービスと言います）は、これを利用するユーザに対し、無料で提供されます。</p>
            <p>本サービスの運営者（以下、当方と言います）は、本サービスにおいて検出された欠陥、およびそれが原因で発生した損失や損害について、一切の責任を負いかねます。</p>
            <p>また、本サービスを利用して、違法売買行為、営利及び非営利目的の勧誘行為等、本サービスを利用したユーザの違法又は不適切な行為により他のユーザに損失や損害が発生した場合でも、当方はかかる損失や損害について一切の責任を負いかねます。本サービスのユーザの任意による利用方法の合法性、適切性について、当方では一切保証いたしかねます。</p>
            <p>以上を了承の上、本サービスによって作成したトークンを詐欺・勧誘・ギャンブル等その他いっさいの不正行為に使用しない事をお約束頂ける方のみご利用頂けます。</p>
        </a-modal>
    </div>
</template>
<script>
import { Modal } from 'ant-design-vue';
import { defineComponent, reactive, h, watch, ref } from "vue";
import useWeb3 from "../composables/useWeb3";
import { getExplorer } from '../helpers/networks'

export default defineComponent({
    setup() {
        const {
            alert,
            alertType,
            alertMessage,
            alertDescription,
            isWeb3Enabled,
            inProgress,
            onboarding,
            currentChain,
            deployContract,
            isDeployComplete,
            transactionHash,
            newContractAddress
        } = useWeb3()

        // フォームの値
        const formState = reactive({
            tokenname: "",
            targetchain: null,
            symbol: "",
            totalsupply: 100000000000000000,
            acceptterms: ''
        })

        const visible = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const terms = () => {
            Modal.info({
                title: '',
                content: h('div', {}, [
                    h('p', ''),
                    h('p', ''),
                    h('p', ''),
                    h('p', '。')
                ]),
            });
        };

        // フォームが送信されたが、バリデーションに失敗した時の処理
        const onFinishFailed = async () => {
            alert.value = true
            alertType.value = 'error'
            alertMessage.value = 'Error'
            alertDescription.value = '入力内容に誤りがあります。表示されている内容を修正して再度実行してください。'
        }

        // フォームが送信された時の処理
        const onFinish = async (values) => {
            alert.value = false
            alertType.value = null
            alertMessage.value = null
            alertDescription.value = null
            await deployContract(values)
        }

        watch(isDeployComplete, () => {
            if(isDeployComplete.value === true){
                Modal.success({
                    title: 'トークンのデプロイに成功しました',
                    content: h('div', {}, [
                        h('p', 'デプロイしたコントラクトがブロックチェーンに浸透するまでには時間を要します。'), 
                        h('p',  {}, [ 
                            h('a',{ target: '_blank', href: getExplorer(formState.targetchain) + `tx/${transactionHash.value}`}, ['進行状況確認URL']) 
                        ]),
                        h('p', '浸透完了後Metamaskで下記のトークンをインポートする事で作成したトークンが取引できるようになります。'), 
                        h('p', `トークンコントラクトアドレス: ${newContractAddress.value}`),
                        h('p', `トークンシンボル: ${formState.symbol}`),
                        h('p', 'トークンの少数桁数: 18'),
                        h('p',  {}, [ 
                            h('a',{ target: '_blank', href: getExplorer(formState.targetchain) + `address/${newContractAddress.value}`}, ['コントラクトをブロックエクスプローラーで表示']) 
                        ]),
                    ]),
                })
            }
            isDeployComplete.value = false
        })

        const handleChangeTargetNetwork = value => {
            alert.value = false
            alertType.value = null
            alertMessage.value = null
            alertDescription.value = null
            // MetaMaskで選択中のネットワークとデプロイ先のネットワークが一致しているかを確認
            if(currentChain.value !== value){
                alert.value = true
                alertType.value = 'error'
                alertMessage.value = 'Error'
                alertDescription.value = 'MetaMaskで選択中のネットワークとデプロイ先のネットワークが一致しません。'
                return;
            }
        }

        return {
            isWeb3Enabled,
            inProgress,
            onboarding,
            formState,
            terms,
            onFinish,
            onFinishFailed,
            transactionHash,
            deployContract,
            currentChain,
            handleChangeTargetNetwork,
            visible,
            showModal
        }
    }
})
</script>
